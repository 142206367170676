import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import {StyledFooter,FooterInnerWrapper,FooterInnerColumn,SocialIconsWrapper,FooterList} from './footer.style'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook,faTwitter,faLinkedin,faInstagram,faYoutube } from '@fortawesome/free-brands-svg-icons'
import { OutboundLink } from "gatsby-plugin-google-analytics"


const Footer = () => {


  const data = useStaticQuery(graphql`
    query {


      file(relativePath: { eq: "kasko.no.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      allSanityArticle(filter: { slug: { current: { ne: null } } },sort: {fields: slug___current,order: ASC}) {
        edges {
          node {
            title
            intro
            isFooter
            isMagasine
            isTips
            isInsurer
            isInsight
            isOfficial
            isAboutKasko
            slug {
              current
            }
            image_text
            image {
              asset {
                url
                _rawMetadata
              }
              crop{
                top
                left
                right
                bottom
              }
              hotspot{
                x
                y
              }
            }
        
          }
        }
      }
    }
  `)


  return(
    <StyledFooter>
      <FooterInnerWrapper>

        <FooterInnerColumn>
          <h3>Tips & triks</h3>
          <FooterList>
            {data.allSanityArticle.edges.map(({node: article},i) => {
              if(article.isFooter && !article.isOfficial && !article.isAboutKasko){
                return(
                  <li key={'ttt'+i} className="li"><Link className="link" to={'/'+article.slug.current}>{article.title}</Link></li>
                )
              }
            })}
          </FooterList>
        </FooterInnerColumn>

        <FooterInnerColumn>
          <h3>Informasjon</h3>
          <FooterList>
            <li className="li"><Link className="link" to="/innhold">Oversikt over innhold</Link></li>
            {data.allSanityArticle.edges.map(({node: article},i) => {
              if(article.isOfficial || (article.isAboutKasko && article.isFooter)){
                return(
                  <li key={'ti'+i} className="li"><Link className="link" to={'/'+article.slug.current}>{article.title}</Link></li>
                )
              }
            })}
          </FooterList>
        </FooterInnerColumn>

        <FooterInnerColumn>
          <SocialIconsWrapper>
            <OutboundLink target="_blank" href="https://www.facebook.com/kasko.no">
              <FontAwesomeIcon icon={faFacebook} size="3x"  color="#be53b1"/>
            </OutboundLink>

            <OutboundLink target="_blank" href="https://www.youtube.com/channel/UCKZRhzF5cPs2pFcogha3cBA/featured">
              <FontAwesomeIcon icon={faYoutube} size="3x"  color="#be53b1"/>
            </OutboundLink>

            <OutboundLink target="_blank" href="https://www.linkedin.com/company/kasko-norway">
              <FontAwesomeIcon icon={faLinkedin} size="3x"  color="#be53b1"/>
            </OutboundLink>

            <OutboundLink target="_blank" href="https://www.instagram.com/kasko.no/">
              <FontAwesomeIcon icon={faInstagram} size="3x"  color="#be53b1"/>
            </OutboundLink>

            {/*<a target="_blank" href="https://twitter.com/KaskoNorge">
              <FontAwesomeIcon icon={faTwitter} size="3x"  color="#be53b1"/>
            </a>*/}
          </SocialIconsWrapper>

          <div className="logoFooter"><Img fluid={data.file.childImageSharp.fluid}/></div>

          <div className="copyright">
            <small>Alt innhold på Kasko.no er opphavsrettslig beskyttet © 2020 Kasko Norway AS.</small>
          </div>
        </FooterInnerColumn>

      </FooterInnerWrapper>

    </StyledFooter>
  )
}

export default Footer
