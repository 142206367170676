import { styled } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Img from "gatsby-image"
import AppBar from '@material-ui/core/AppBar'
import React from "react"

export const HeaderAppBar = styled(AppBar)({
  borderTop: '4px solid #00dd86',
  background: '#f7f7f7',
  boxShadow: '0 3px 5px 2px rgba(185, 186, 187, .3)',
});

export const HeaderLogo = styled(Img)({
  margin: '0',
  marginLeft: '10px',
  height: '50px',
});

/*export default function StyledComponents() {
  return <MyAppBar></MyAppBar>;
}*/
