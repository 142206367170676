import { Link,graphql,useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from 'gatsby-image'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { makeStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import {HeaderAppBar,HeaderLogo} from './header.style'


const Header = () => {


  const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "kasko.no2.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
  `)

  const showContent = () =>{
    window.location.href = '/innhold';
  }

  return(
      <header>
          <HeaderAppBar position="fixed">
            <Toolbar style={{justifyContent:'space-between',alignItems: 'center'}}>
              <div style={{display:'flex',alignSelf: 'flex-center'}}>
                <IconButton onClick={showContent} edge="start" aria-label="Meny">
                  <MenuIcon />
                </IconButton>
                <Link style={{alignSelf: 'center'}} title="Til forsiden" to="/"><HeaderLogo fixed={data.file.childImageSharp.fixed}/></Link>
              </div>
              <button aria-label="Få tilbud nå" className="cta" style={{borderRadius:'2px',fontSize: '110%',display:'block',alignSelf: 'flex-center',padding:'10px',paddingLeft:'15px',paddingRight:'15px',background:'#f6d000',fontWeight:'700',border:'0px solid #e0bd16'}}

                  onClick={e => {
                    // To stop the page reloading
                    e.preventDefault()
                    // Lets track that custom click
                    trackCustomEvent({
                      // string - required - The object that was interacted with (e.g.video)
                      category: "Get quote",
                      // string - required - Type of interaction (e.g. 'play')
                      action: "Click",
                      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                      label: "Get quote button - header",
                      // number - optional - Numeric value associated with the event. (e.g. A product ID)
                      value: 1
                    })
                    //... Other logic here
                  }}

              >
                Få tilbud nå
              </button>
            </Toolbar>
          </HeaderAppBar>
      </header>
  )
}

export default Header

