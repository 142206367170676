import styled from 'styled-components'
import React from "react"


export const StyledFooter = styled.footer`
  background-color: #f7f7f7;
  border-top: 4px solid rgba(185, 186, 187, .2);
  padding: 40px;
  border-bottom: 4px solid #be53b1;
  
  .logoFooter{
    max-width: 240px;
    margin-left:auto;
    margin-right: auto;
  }
  
  .copyright{
    margin-top:60px;
    text-align:center;
    opacity:0.5;
  }
`;

export const FooterInnerWrapper = styled.div`
  height: 100%;
  width:100%;
  display:flex;
  align-items: stretch;
  justify-content: space-evenly;
  flex-wrap:wrap;
`;

export const FooterInnerColumn = styled.div`
  height: 100%;
  flex: 1;
  align-content: end;
`;

export const SocialIconsWrapper = styled.div`
  width: 100%;
  justify-content: space-evenly;
  display: flex;
  padding-bottom: 40px;
`;

export const FooterList = styled.ul`
  padding:0;
  margin:0;
  list-style:none;
  margin-right:25px;
  margin-bottom:40px;
  min-width:300px;

  .li{
    padding-top:5px;
  }
    
  .link{
    color:rgba(0, 0, 0, 0.87);
    color: #be53b1;
    color:gray;
    text-decoration: none;
    &:hover{
      opacity: 0.5;
    }
  }
`;
